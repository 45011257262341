@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

.main {
  width: 90vw !important;
}

.masthead {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #7f7fd5, #91eae4) !important;
  height: 100vh;
}
.masthead h1.ui.header {
  font-size: 4em;
}
